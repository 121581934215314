export const htmlWithStyles = (missionName, rewardName, rewardValue, imgSrc, missionScheduling, id) => `
<style>
  .mission-block {
    width: 200px;
    height: 80px;
    border: 1px solid #406A8C;
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #DCE6EF;
    border-radius: 10px;
    position: relative;
    overflow: visible;
    z-index: 1;
    user-select: none;
    cursor: pointer;
  }

  .image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 51px;
    border-radius: 5px;
    border: 1px solid #406A8C;
    overflow: hidden;
    flex-shrink: 0;
    user-select: none;
  }

  .image-wrapper img {
    height: 100%;
    object-fit: cover;
    max-width: 100%;
    border-radius: 5px;
    user-select: none;
  }

  .mission-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 105%;
    max-width: 140px;
    overflow: hidden;
    margin-left: 10px;
    flex-grow: 1;
    user-select: none;
  }

  .mission-name {
    font-size: 16px;
    color: #141E28;
    text-transform: capitalize;
    user-select: none;
  }

  .reward-data {
    font-size: 14px;
    color: #304F69;
    user-select: none;
  }

  .mission-block > button {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #BB1196;
    color: #fff;
    border-radius: 5px;
    border: none;
    z-index: 10;
    user-select: none;
  }

  .connection-options {
    position: absolute;
    top: 50%;
    right: -55%;
    transform: translateY(-50%);
    display: none;
    z-index: 999;
    user-select: none;
  }

  .connection-options div {
    cursor: pointer;
    padding: 10px 20px;
    border-right: 10px;
    margin-top: 10px;
    background-color: #DCE6EF;
    border-radius: 10px;
    user-select: none;
  }
  /* Popup window styles */
  .mission-popup {
    position: absolute;
    width: 200px;
    top: 105%;
    left: 0;
    display: none; 
    padding: 10px;
    background-color: #FFF;
    border: 1px solid #406A8C;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    user-select: none;
  }
  
  .popup-header {
    font-size: 16px;
    color: #3C4B64;
  }
  
  .popup-body {
    font-size: 12px;
    color: #3C4B64;
  }
</style>
<div class="mission-block" data-id="${ id }">
  <div class="image-wrapper">
    <img src="${ imgSrc }" alt=""/>
  </div>
  <div class="mission-info">
    <span class="mission-name">${ missionName }</span>
    <span class="mission-name">${ id }</span>
    <span class="reward-data">
        <span>${ rewardName }</span>
        <span>${ rewardValue }</span>
      </span>
  </div>
  <button class="connection-options-button">+</button>
  <div class="connection-options">
    <div class="option-must">Must</div>
    <div class="option-should">Should</div>
    <div class="option-mustNot">Mustn't</div>
  </div>
  <!-- Mission info popup -->
  <div class="mission-popup">
    <div class="popup-header">${ missionName }</div>
    <div class="popup-body">Start: ${ formatDate(missionScheduling.startDate) }</div>
    <div class="popup-body">End: ${ formatDate(missionScheduling.endDate) }</div>
  </div>
</div>

<script>

</script>
`;

function formatDate(isoString) {
  if (!isoString) {
    return ' ';
  }

  const date = new Date(isoString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${day}/${month}/${year}, ${hours}:${minutes}`;
}
