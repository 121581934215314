<template>
    <div
      ref="graphContainer"
      class="graph-container"
      @wheel="onMouseWheel"
      @mousedown="onDragStart"
      @mousemove="onDragging"
      @mouseup="onDragEnd"
    ></div>

</template>

<script>
import * as d3 from 'd3';
import ClCardArrow from '@/shared/UI/ClCardArrow.vue';
import { mapActions } from 'vuex';
import Modal from '@/shared/UI/Modal.vue';
import { dateFormate } from '@/utils/dateFormate';
import { htmlWithStyles } from '@/components/mission/missionBlock'

export default {
  name: 'MissionsGraph',
  components: { Modal, ClCardArrow },
  props: {
    graphData: Object,
    missionsData: Array
  },
  data() {
    return {
      basicIsCollapsed: true,
      iconsUri: {},
      isShowModal: false,
      isModalSuccessButton: true,
      modalTitle: '',
      messageGeneral: '',
      edgeTypeColors: {
        'MUST': '#6FCF97',
        'SHOULD': 'rgb(238, 187, 0)',
        'MUST-NOT': '#EB5757'
      },
      entityId: null,
      scale: 1,
      isDragging: false,
      dragStartCoords: { x: 0, y: 0 },
    };
  },
  async mounted() {
    await this.loadIcons();
    await this.createGraph();
  },
  watch: {
    graphData: {
      deep: true,
      handler: async function (val) {
        await this.loadIcons();
        await this.createGraph();
      }
    },
    missionsData: {
      deep: true,
      handler: function (val) {

      }
    }
  },
  methods: {
    ...mapActions('files', ['handleGetFileObjects_item']),
    async createGraph() {
      d3.select(this.$refs.graphContainer).selectAll('*').remove();

      const graphData = this.graphData;
      const nodes = graphData.nodes;
      const edges = graphData.graphs[0].edges;

      const nodeWidth = 200;
      const nodeHeight = 80;

      const cardElement = this.$el.parentElement;

      const width = cardElement.clientWidth;
      const height = cardElement.clientHeight;

      // Forming a hierarchy for d3.tree()
      const rootNodeId = edges.find(edge => edge.graphEdgeType === 'ROOT').tailEntityId;
      const root = d3.hierarchy({ children: this.buildTree(nodes, edges, rootNodeId) });

      const treeLayout = d3.tree()
        .size([height, width])
        .separation((a, b) => (a.parent === b.parent ? 1 : 1) / a.depth); // separation: function that determines the distance between nodes.

      // Applying parameters to treeLayout
      const treeData = treeLayout(root);
      const nodesData = treeData.descendants();

      // Optionally: Adjust node positions if needed
      nodesData.forEach((d, i) => {
        d.y = d.depth * 320; // Change the value as needed
      });

      const linksData = treeData.links().map(link => {
        const sourceEntityId = link.source.data.entityId || rootNodeId;

        const edge = edges.find(e => {
          const targetEntityId = link.target.data.entityId;
          return (
            (e.headEntityId === targetEntityId && e.tailEntityId === sourceEntityId) ||
            (e.headEntityId === sourceEntityId && e.tailEntityId === targetEntityId)
          );
        });

        const edgeType = edge ? edge.graphEdgeType : null;
        const edgeColor = this.getEdgeColor(edgeType);

        return {
          ...link,
          edgeColor,
        };
      });

      const svg = d3.select(this.$refs.graphContainer)
        .append('svg')
        .attr('width', width)
        .attr('height', height)
        .append('g')
        .attr('transform', `translate(130,0)`);

      // Define the curve generator
      const curveGenerator = d3.line()
        .curve(d3.curveBasis)
        .x(d => d[0])
        .y(d => d[1]);

      svg.selectAll('.link')
        .data(linksData)
        .enter()
        .append('path')
        .attr('class', 'link')
        .style('stroke', d => d.edgeColor)
        .style('stroke-width', 2)
        .style('fill', 'none')
        .attr('d', d => {
          const pathData = this.getPathData(d);
          return curveGenerator(pathData.map(point => [point[0] * this.scale, point[1] * this.scale]));
        })
        .attr('marker-end', 'url(#arrowhead)');

      const node = svg.selectAll('.node')
        .data(nodesData)
        .enter()
        .append('g')
        .attr('id', (d, i) => {
          return this.missionsData[i].id;

        })
        .attr('class', 'node')
        .attr('transform', (d, i) => {
          return `translate(${ d.y },${ d.x })`;
        });
      const self = this;
      node.append('foreignObject')
        .attr('width', nodeWidth)
        .attr('height', nodeHeight)
        .attr('x', -nodeWidth / 2)
        .attr('y', -nodeHeight / 2)
        .attr('overflow', 'visible')
        .append('xhtml:div')
        .html(d => {
          if (d.depth === 0) {
            const rootMission = self.missionsData.find(item => item.id === rootNodeId);
            const imgSrc = this.iconsUri[rootNodeId] ? this.iconsUri[rootNodeId] : '';
            const reward = rootMission && rootMission.rewards && rootMission.rewards[0] ? rootMission.rewards[0] : null;
            const rewardValue = reward ? reward.rewardValue : '';
            const rewardName = reward ? reward.name : '';
            const missionScheduling = rootMission.scheduling;

            return htmlWithStyles(rootMission.name, rewardName, rewardValue, imgSrc, missionScheduling, rootMission.id);
          } else {
            const mission = self.missionsData.find(item => item.id === d.data.entityId);
            const name = d.data.missionData && d.data.missionData.name ? d.data.missionData.name : '';
            const imgSrc = this.iconsUri[d.data.entityId] ? this.iconsUri[d.data.entityId] : '';
            const reward = mission && mission.rewards && mission.rewards[0] ? mission.rewards[0] : null;
            const rewardValue = reward ? reward.rewardValue : '';
            const rewardName = reward ? reward.name : '';
            const missionScheduling = mission.scheduling;

            return htmlWithStyles(name, rewardName, rewardValue, imgSrc, missionScheduling, mission.id);
          }
        })

      node.selectAll('.connection-options-button').on('click', function() {
        const missionBlock = d3.select(this.parentNode);
        const connectionOptions = missionBlock.select('.connection-options');

        const isVisible = connectionOptions.style('display') === 'none' ? 'block' : 'none';
        connectionOptions.style('display', isVisible);

        const missionPopup = missionBlock.select('.mission-popup');

        if (isVisible === 'block') {
          missionPopup.style('display', 'none'); // Hide popup when options are visible
        }
      });

      node.on('mouseover', function() {
        const missionBlock = d3.select(this);
        const connectionOptions = missionBlock.select('.connection-options');

        // Show missionPopup on hover only if connectionOptions are not visible
        if (connectionOptions.style('display') === 'none') {
          const missionPopup = missionBlock.select('.mission-popup');
          missionBlock.raise();
          missionPopup.style('display', 'block')
        }
      }).on('mouseout', function() {
        const missionBlock = d3.select(this);
        const missionPopup = missionBlock.select('.mission-popup');
        missionPopup.style('display', 'none')
      });

      node.selectAll('.option-must').on('click', function() {
        const missionBlock = d3.select(this.closest('.mission-block'));
        const nodeId = missionBlock.attr('data-id');
        console.warn('nodeId', nodeId);
        missionBlock.select('.connection-options').style('display', 'none');
        document.dispatchEvent(new CustomEvent('optionSelected', { detail: { nodeId, option: 'must' } }));
      });

      node.selectAll('.option-should').on('click', function() {
        const missionBlock = d3.select(this.closest('.mission-block'));
        const nodeId = missionBlock.attr('data-id');
        missionBlock.select('.connection-options').style('display', 'none');
        document.dispatchEvent(new CustomEvent('optionSelected', { detail: { nodeId, option: 'should' } }));
      });


      node.selectAll('.option-mustNot').on('click', function() {
        const missionBlock = d3.select(this.closest('.mission-block'));
        const nodeId = missionBlock.attr('data-id');
        missionBlock.select('.connection-options').style('display', 'none');
        document.dispatchEvent(new CustomEvent('optionSelected', { detail: { nodeId, option: 'mustNot' } }));
      });

      node.on('click', (event, d) => {
        const entityId = d.data.entityId ? d.data.entityId : rootNodeId;
        this.showModal(entityId);
      });
    },
    getPathData(d) {
      if (!d || !d.source || !d.target) {
        return {};
      }

      const source = [d.source.y, d.source.x];
      const target = [d.target.y, d.target.x];
      const controlPoint1 = [(source[0] + target[0]) / 2, source[1]];
      const controlPoint2 = [(source[0] + target[0]) / 2, target[1]];

      return [source, controlPoint1, controlPoint2, target];
    },
    onMouseWheel(event) {
      const delta = event.deltaY;
      if (delta > 0) {
        // this.scale /= 1.2;
      } else {
        // this.scale *= 1.2;
      }
      // this.updateGraph();

      event.preventDefault();
    },
    updateGraph() {
      const cardElement = this.$el.parentElement;
      const cardWidth = cardElement.clientWidth;
      const cardHeight = cardElement.clientHeight;

      d3.selectAll('.node')
        .attr('transform', d => `translate(${ d.y * this.scale },${ d.x * this.scale })`);

      d3.selectAll('.link')
        .attr('d', d => {
          const pathData = this.getPathData(d);
          return d3.line()
            .curve(d3.curveBasis)
            .x(d => d[0] * this.scale)
            .y(d => d[1] * this.scale)(pathData);
        });

      d3.select(this.$refs.graphContainer)
        .select('svg')
        .attr('width', cardWidth)
        .attr('height', cardHeight);
    },
    onDragStart(event) {
      this.isDragging = true;
      this.dragStartCoords = { x: event.clientX, y: event.clientY };
    },
    onDragging(event) {
      if (this.isDragging) {
        const dx = (event.clientX - this.dragStartCoords.x) / this.scale;
        const dy = (event.clientY - this.dragStartCoords.y) / this.scale;
        this.dragStartCoords = { x: event.clientX, y: event.clientY };

        d3.selectAll('.node')
          .attr('transform', function (d) {
            return `translate(${ (d.y += dx) },${ (d.x += dy) })`;
          });
        this.updateGraph();
      }
    },
    onDragEnd() {
      this.isDragging = false;
    },
    getEdgeColor(edgeType) {
      switch (edgeType) {
        case 'MUST':
          return '#6FCF97';
        case 'SHOULD':
          return 'rgb(238, 187, 0)';
        case 'MUST-NOT':
          return '#EB5757';
        default:
          return '#ccc';
      }
    },
    showModal(entityId) {
      this.isShowModal = true;
      this.entityId = entityId;

      const mission = this.missionsData.find(mission => mission.id === entityId);
      this.modalTitle = `Name: ${ mission.name }`;

      const startDate = dateFormate(mission.scheduling.startDate).split(',')[0];
      const endDate = dateFormate(mission.scheduling.endDate).split(',')[0];

      this.messageGeneral = `
          Status: ${ mission.status } <br>
          Start date: ${ startDate } <br>
          End date: ${ endDate } <br>
      `;
    },
    closeModal() {
      this.isShowModal = false;
      this.entityId = null;
      this.modalTitle = '';
      this.messageGeneral = '';
    },
    buildTree(nodes, edges, rootNodeId) {
      const buildTreeRecursive = (parentId = null) => {
        const children = edges
          .filter(edge => edge.headEntityId === parentId)
          .map(edge => {
            const childNode = nodes.find(node => node.entityId === edge.tailEntityId);
            const missionData = this.missionsData.find(mission => mission.id === childNode.entityId);

            return {
              ...childNode,
              missionData: { ...missionData, icon: this.iconsUri[missionData.id] },
              children: buildTreeRecursive(childNode.entityId),
            };
          });
        return children.length ? children : null;
      };

      return buildTreeRecursive(rootNodeId);
    },
    async loadIcons() {
      const iconUris = {};

      for (const mission of this.missionsData) {
        const iconId = mission.icon;
        if (iconId) {
          const file = await this.handleGetFileObjects_item({ idArray: [iconId] });
          if (file && file.length > 0) {
            iconUris[mission.id] = file[0].uri;
          } else {
            iconUris[mission.id] = null;
          }
        } else {
          iconUris[mission.id] = null;
        }
      }

      this.$set(this, 'iconsUri', iconUris);
    },
  },
};
</script>

<style scoped lang="scss">
.node text {
  user-select: none;
}

.link {
  stroke: #999;
  stroke-opacity: 0.6;
  stroke-width: 1.5px;
}

.link.suit {
  stroke: blue;
}

.link.licensing {
  stroke: green;
}

.link.resolved {
  stroke: red;
}

circle {
  fill: #ff5722;
  stroke: #fff;
  stroke-width: 1.5px;
}

text {
  font: 10px sans-serif;
}

.high-z-index {
  z-index: 1000 !important;
}
</style>
