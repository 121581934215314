<template>
  <div>
    <CModal
      :show.sync="showModal"
      centered
      fade
    >
      <template #header>
        <h5 class="modal-title">{{ 'Preview reward' }}</h5>
        <button type="button" class="close" @click="closeByIcon">
          <span aria-hidden="true">&times;</span>
        </button>
      </template>

      <div>
        <!--    Icon        -->
        <ClCardRow :label="rewardsTexts.previewPage.iconLabel" :content="rewardData.icon"/>
        <!--     Reward Name         -->
        <ClCardRow :label="rewardsTexts.previewPage.nameLabel" :content="rewardData.name"/>
        <!--    Value        -->
        <ClCardRow :label="rewardsTexts.previewPage.valueLabel" :content="rewardData.rewardValue"/>
        <!--    Reward Rank        -->
        <ClCardRow :label="rewardsTexts.previewPage.rewardRankLabel" :content="rewardData.rewardRank"/>
        <!--    Reward Type         -->
        <!--        <ClCardRow :label="rewardsTexts.previewPage.typeLabel" :content="rewardData.rewardType.key"/>-->
        <!--    Reward Type         -->
        <ClCardRow :label="rewardsTexts.previewPage.typeIdLabel" :content="rewardData.rewardTypeId"/>
        <!--    Period        -->
        <ClCardRow :label="rewardsTexts.previewPage.periodLabel" :content="rewardData.period"/>
        <!--    Delay        -->
        <ClCardRow :label="rewardsTexts.previewPage.delayLabel" :content="rewardData.delay"/>
        <!--     Point In Time        -->
        <ClCardRow :label="rewardsTexts.previewPage.pointLabel" :content="dateFormate(rewardData.pointInTime)"/>
        <!--     Description        -->
        <ClCardRow :label="rewardsTexts.previewPage.descriptionLabel" :content="rewardData.description"/>
        <!--      constraints        -->
        <ClCardRow :label="rewardsTexts.previewPage.constraintsLabel" v-if="rewardData.constraints">
          <template #content>
            <ul class="zq--list">
              <li v-for="(group, index) in rewardData.constraints"
                  :key="index" class="group__colored mr-2">{{ group }}
              </li>
            </ul>
          </template>
        </ClCardRow>
        <!--      add constraints        -->
        <ClCardRow :label="rewardsTexts.previewPage.constraintsLabel" v-if="rewardData.addConstraints">
          <template #content>
            <ul class="zq--list">
              <li v-for="(group, index) in rewardData.addConstraints"
                  :key="index" class="group__colored mr-2">{{ group }}
              </li>
            </ul>
          </template>
        </ClCardRow>
        <!--      Metadata        -->
        <ClCardRow :label="rewardsTexts.previewPage.metadataLabel" v-if="rewardData.metadata">
          <template #content>
            <ul class="members-metadata--list">
              <li
                v-for="(meta, index) in Object.entries(rewardData.metadata)"
                class="members-metadata--item"
                :key="index"
              >
                <span>{{ meta[0] }} : </span><span>{{ meta[1] }}</span>
              </li>
            </ul>
          </template>
        </ClCardRow>
      </div>
      <template #footer-wrapper></template>
    </CModal>
  </div>
</template>

<script>
import ClCardRow from '@/shared/UI/ClCardRow.vue';
import { rewardsTexts } from '@/config/pageTexts/rewards.json';
import { dateFormate } from '@/utils';

export default {
  name: 'PreviewRewardModal',
  components: { ClCardRow },
  props: {
    rewardData: Object,
    isShowPreviewModal: Boolean,
  },
  data() {
    return {
      showModal: false,
      rewardsTexts: {
        ...rewardsTexts
      },
    };
  },
  watch: {
    isShowPreviewModal(val) {
      this.showModal = val;
      // this.rerenderKey++;
    },
  },
  methods: {
    closeByIcon() {
      this.$emit('closeModal');
      this.modal = false;
    },
    dateFormate(val) {
      return dateFormate(val);
    }
  }
};
</script>


<style scoped lang="scss">
::v-deep .modal-footer {
  display: none;
}
</style>
