<template>
  <div class="connection-options">
    <div @click="$emit('chooseConnection', 'must')">Must</div>
    <div @click="$emit('chooseConnection', 'should')">Should</div>
    <div @click="$emit('chooseConnection', 'mustNot')">Mustn't</div>
  </div>
</template>

<script>
export default {
  name: 'ConnectionOptions'
};
</script>

<style scoped lang="scss">
.connection-options div {
  cursor: pointer;
  padding: 10px 30px;
  border-right: 10px;
  margin-top: 10px;
  background-color: #DCE6EF;
  border-radius: 10px;

}
</style>
